<template>
  <base-dialog title="View project">
    <template #default>
      <project-view :data="data"></project-view>
    </template>
  </base-dialog>
</template>

<script>
import ProjectView from "./ProjectView.vue";
export default {
  components: { ProjectView },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
