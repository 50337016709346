<template>
  <span class="header">Update spent hours</span>

  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-4">
      <label for="selBudget">Budget</label>
      <input id="selBudget" type="text" readonly v-model.number="budget" />
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="selSpent">Spent (h)</label>
      <input type="number" id="selSpent" v-model.number="spent" />
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label>Act</label>
      <base-button small @click="update" label="Save"></base-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "hourSpent", "hourBudget"],
  emits: ["update"],
  data() {
    return {
      spent: null,
      budget: null,
    };
  },
  methods: {
    update() {
      this.$emit('update', {id: this.id, spend: this.spent});
    }
  },
  created() {
    this.spent = this.hourSpent;
    this.budget = this.hourBudget;
  },
};
</script>

<style scoped>
input {
  width: 80px;
}

.header {
  color: #3F87A9;

  font-weight: bold;
}
</style>
